<!--
 * @Version: 2.0
 * @Autor: YangKX
 * @Date: 2023-04-04 14:22:31
 * @LastEditors: YangKX
 * @LastEditTime: 2023-05-15 18:34:58
-->

<template>
  <div class="page-list">
    <img style="width:100%" src="https://attachment.shenglisige.com/upload/jt.png" alt="集团">
  </div>
</template>

<script>
export default {
  components: {

  },
  data() {
    return {

    };
  },
  created() {

  },
  mounted() {

  },
  methods: {

  },
};
</script>
<style lang="less" scoped>
.page-list {
  margin: 18px 11px 0px 8px;
  border-radius: 2px;
  height: calc(100vh - 73px);
  overflow: hidden;
  overflow-y: auto;
}
</style>